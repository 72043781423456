import { useState, useEffect } from 'react'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export interface Result {
  browserHash: string
  components: Components
}

export interface Components {
  colorDepth?: number
  platform?: string
  screenResolution?: (number | null)[]
  timeZone?: string
}

export const useFingerprint = (): Result | null => {
  const [components, setComponents] = useState<Result | null>(null)
  const fpPromise = FingerprintJS.load()

  const initFingerprintJS = async () => {
    const fp = await fpPromise
    const { components, visitorId } = await fp.get()
    setComponents({
      browserHash: visitorId,
      components: {
        colorDepth: components.colorDepth.value,
        platform: components.platform.value,
        screenResolution: components.screenResolution.value
      }
    })
  }

  useEffect(() => {
    initFingerprintJS()
  }, [])

  return components
}
