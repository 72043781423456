import { useEffect } from 'react'

import { useFingerprint } from './hooks/useFingerprint'
import { useCreateFingerprint, useFetchIP } from './queries/queries'

import './App.css'

export default function App() {
  const { components, browserHash } = useFingerprint() ?? {}
  const { data, mutate } = useCreateFingerprint()
  const { data: ip } = useFetchIP()

  useEffect(() => {
    if (components && browserHash && ip) {
      mutate({ components, browserHash, ip })
    }
  }, [components, browserHash, ip])

  return (
    <div>
      {!data ? (
        <p>Loading...</p>
      ) : (
        <>
          <p>{`Browser Hash: ${data?.browserHash}`}</p>
          <p>{`Attributes to Device IP Hash: ${JSON.stringify(data?.params)}`}</p>
          <p>{`Device Hash: ${data?.deviceIPHash}`}</p>
          <br />
          <p>
            <b>{`FingerprintID: ${data?.fingerprintID}`}</b>
          </p>
        </>
      )}
    </div>
  )
}
