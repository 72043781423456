import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { Result } from '../hooks/useFingerprint'

let apiURL = 'https://device-fingerprint-v2-vkffxmwbzq-uc.a.run.app'
export async function postResolve(url: string, body: unknown) {
  const res = await axios.post(apiURL + url, body)
  return res.data
}

export function useCreateFingerprint() {
  return useMutation(async (body: { ip: string } & Result) => await postResolve(``, body))
}

export function useFetchIP() {
  return useQuery<string>(
    [`useFetchIP`],
    async () => (await axios.get('https://api.ipify.org')).data
  )
}
